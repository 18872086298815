import { Text } from '@ui/elements/text'
import Logo from '@ui/icons/logo'
import { EnvelopeIcon } from '@ui/icons/envelope'

const STRIPE_LINK = 'https://connect.stripe.com/express_login'
const HELP_EMAIL = 'help@twoyay.com'

export function ShutdownLanding() {
  return (
    <div className='flex flex-col items-center w-full h-screen bg-neutral-50 pt-[120px] relative overflow-y-hidden px-3 md:px-0'>
      <img
        src='/images/ty-logo-bg.png'
        alt='hello'
        className='absolute bottom-0 z-0 hidden md:block'
      />
      <div className='z-50 flex flex-col items-center w-full max-w-2xl text-center py-4 px-8 border rounded-md shadow md:absolute md:top-[120px] bg-white'>
        <div className='h-[127px] w-[127px] rounded-full bg-indigo-600 flex items-center justify-center -translate-y-[80px]'>
          <Logo />
        </div>
        <div className='max-w-sm -translate-y-[80px] pt-8'>
          <div className='space-y-3'>
            <Text as='h1' xl semibold>
              TwoYay is no longer available.
            </Text>
            <Text as='p' lg className='text-neutral-600'>
              If you had an account with us you can still access your Stripe account{' '}
              <a
                href={STRIPE_LINK}
                target='_blank'
                rel='noreferrer'
                className='font-semibold text-indigo-600 underline'
              >
                here
              </a>
              .
            </Text>

            <div className='pt-6 space-y-6'>
              <Text lg className='text-neutral-600'>
                Have a question?
              </Text>
              <a
                className='inline-block px-6 py-2 text-lg text-indigo-600 border border-indigo-600'
                href={`mailto:${HELP_EMAIL}`}
              >
                <div className='flex items-center'>
                  <EnvelopeIcon className='mr-4' /> {HELP_EMAIL}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
