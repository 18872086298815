import React from 'react'

export function EnvelopeIcon({ className = 'w-5 h-5' }: { className?: string }) {
  return (
    <div className={className}>
      <svg width="24" height="18" viewBox="0 0 24 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.37695 0.5H22.623C22.9862 0.503595 23.3334 0.650128 23.5893 0.907858C23.8452 1.16559 23.9892 1.51376 23.9902 1.87695V16.4785C23.9923 16.7571 23.9091 17.0297 23.752 17.2598C23.7237 17.3335 23.6797 17.4001 23.623 17.4551C23.585 17.4915 23.5415 17.5218 23.4941 17.5449C23.2477 17.7529 22.9357 17.867 22.6133 17.8672H1.37695C1.01546 17.8636 0.669772 17.7184 0.414143 17.4628C0.158514 17.2072 0.0133274 16.8615 0.00976562 16.5V1.87695C0.0107753 1.51376 0.154834 1.16559 0.410736 0.907858C0.666638 0.650128 1.01378 0.503595 1.37695 0.5ZM1.31836 15.8984L8.50195 8.43359L1.31836 2.36328V15.8984ZM9.58399 9.36523L1.9082 16.7988H22.0254L14.6914 9.36523L12.543 11.1641C12.4483 11.2427 12.3291 11.2857 12.2061 11.2857C12.083 11.2857 11.9638 11.2427 11.8691 11.1641L9.58399 9.36523ZM15.7559 8.41992L22.6816 15.959V2.29688L15.7559 8.41992ZM1.99414 1.55664L12.1855 9.81641L21.9277 1.55664H1.99414Z" />
      </svg>
    </div>
  )
}
