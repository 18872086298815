import React from 'react'

function Logo({
  fill = '#fff',
  abbreviated = false,
  w = 76,
  h = 24
}: {
  h?: number
  abbreviated?: boolean
  w?: number
  fill?: string
}) {
  return (
    <svg width={w} height={h} viewBox='0 0 76 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.95 9.92002C7.68381 9.92002 9.9 7.70383 9.9 4.97002C9.9 2.23621 7.68381 0.0200195 4.95 0.0200195C2.21619 0.0200195 0 2.23621 0 4.97002C0 7.70383 2.21619 9.92002 4.95 9.92002Z'
        fill={fill}
      />
      <path
        d='M4.95 23.08C7.68381 23.08 9.9 20.8638 9.9 18.13C9.9 15.3962 7.68381 13.18 4.95 13.18C2.21619 13.18 0 15.3962 0 18.13C0 20.8638 2.21619 23.08 4.95 23.08Z'
        fill={fill}
      />
      <path
        d='M22.69 9.03998L27.96 0.039978H33.73L25.32 14.22V23.12H20.03V14.22L11.62 0.039978H17.72L22.69 9.03998Z'
        fill={fill}
      />
      {!abbreviated && (
        <>
          <path
            d='M39.84 0H47.42L56.49 23.08H50.72L48.94 18.3H38.32L36.54 23.08H30.77L39.84 0ZM47.16 13.52L43.63 4.09L40.11 13.52H47.16Z'
            fill={fill}
          />
          <path
            d='M64.58 9.03998L69.86 0.039978H75.63L67.22 14.22V23.12H61.94V14.22L53.54 0.039978H59.64L64.58 9.03998Z'
            fill={fill}
          />
        </>
      )}
    </svg>
  )
}

export default Logo
